import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const VerificationMessage = styled.div`
  font-size: 1rem;
  text-align: center;
  padding: 1rem 0;
`

export const VerificationStatusTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  padding: 4rem 0 2rem;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  small {
    padding: 0.5rem 0;
    text-align: center;
    display: block;
  }

  ${themeMedia.desktop`
        width: ${(props) => (props.isPage ? '770px' : '490px')};
        margin: 0 auto;
        padding: 0;
    `};
`

export const StyledImageWrapper = styled.div`
  position: relative;

  .main-img {
    width: 100px;
    height: 100px;

    ${themeMedia.desktop`
      width: 100px;
      height: 100px;
    `}
  }

  .little-icon {
    position: absolute;
    right: -5px;
    width: 30px;
    height: 30px;

    ${themeMedia.desktop`
      width: 30px;
      height: 30px;
    `}
  }
`
