import { EnvConfig } from '../config/EnvConfig'
import { isBrowser } from './generic'

export const cookiePrefix = EnvConfig.GATSBY_COOKIE_PREFIX

export const getCookie = (name) => {
  if (!isBrowser()) return
  const regex = new RegExp('(^|;\\s*)' + name + '=([^;]*)')
  const match = document.cookie.match(regex)
  return match ? decodeURIComponent(match[2]) : ''
}

export const deleteCookie = (name, domain) => {
  setCookie(name, '', -50000, domain)
}

export const setCookie = (name, value, exdays, domain) => {
  var exdate = new Date()
  exdate.setDate(exdate.getDate() + exdays)

  value =
    escape(value) + (exdays == null ? '' : '; expires=' + exdate.toUTCString())
  if (domain) {
    value += '; domain=' + escape(domain) + ';path=/'
  }

  document.cookie = name + '=' + value + ';path=/'
}

export const checkCookieValue = (value) => {
  return value && value !== 'deleted'
}

export const getUpdatedToken = (cookieName) => {
  if (!isBrowser()) return
  const token = getCookie(cookieName)
  return checkCookieValue(token) ? token : sessionStorage.getItem(cookieName)
}
