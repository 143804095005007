import { styled } from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const StyledBottomBar = styled.div`
  ${themeMedia.maxMobile`
        position: fixed;
        width: 100%;
        z-index: 499;
        height: 60px;
        bottom: -1px;
        display: block;
        a {
          text-decoration: none;
        }
    `}
  display: none;
`
