import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Widget } from '@typeform/embed-react'
import { ErrorBoundary } from 'react-error-boundary'

import GlobalStyle from '../../../styles/globalStyle'

import { AuthContext } from '../../../context/authProvider'
import { PaymentsProvider } from '../../../context/paymentProvider'
import { ModalProvider } from '../../../context/modalProvider'

import LiveAgentScript from '../../../templates/externalScripts/liveAgentScript'
import LicenseScript from '../../../templates/externalScripts/licenseScript'

import { Header } from '../header'
import { Footer } from '../footer'
import { FooterV1 } from '../../../atomic-components/molecules/footerV1'
import { Maintenance } from '../../../atomic-components/atoms/maintenance'
import { CashbackDrawer } from '../../molecules/cashbackDrawer'
import { MobileRoutesListener } from '../../atoms/mobileRoutesListener'
import { If } from '../../atoms/if'
import { LoadingScreen } from '../../atoms/loadingScreen'
import { SuspensfulComponent } from '../../atoms/suspensfulComponent'
import { CpfModal } from '../../molecules/cpfModal'
import { ErrorFallback } from '../../../components/common/errorFallback'
import { SeoContent } from '../../molecules/seoContent'
import { StyledMain } from './styles'
import { usePopups } from '../../../context/popupsProvider'
import { Popup } from '../../molecules/popup'

import useFeatures from '../../../hooks/useFeatures'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { EnvConfig } from '../../../config/EnvConfig'
import {
  getMobileAppVersion,
  isMobileApp,
} from '../../../utils/mobileAppFunctionality'
import { useMaintenanceApiData } from '../../../hooks/graphqlStaticQuery/useMaintenanceApiData'
import { isBrowser } from '../../../utils/generic'
import useIntercomMessenger from '../../../hooks/useIntercomMessenger'
import { useSEOContentVisibility } from '../../../hooks/useSEOContentVisibility'
import { defaultTheme, ThemeProvider } from '@techmobilt/ui-components'
import { isFbUser } from '../../../helpers/urlHelper'

import CookiesBanner from '../../molecules/cookiesBanner'
import CoolOffBanner from '../../molecules/coolOffBanner'
import RealityCheckModal from '../../molecules/realityCheckModal'
import { GeolocationTracker } from '../../atoms/GeolocationTracker'
import { BottomNavigation } from '../bottomNavigation'
import { getCookie } from '../../../utils/cookies'
import { KYCVerificationModal } from '../KYCVerificationModal'

const locale = process.env.GATSBY_INTL_LOCALE

const growthbook = new GrowthBook({
  apiHost: EnvConfig.GATSBY_GROWTHBOOK_URL,
  clientKey: EnvConfig.GATSBY_GROWTHBOOK_KEY,
  enableDevMode: true,
})

const Layout = (props) => {
  const {
    children,
    seoPathParams,
    hideHeader = false,
    hideFooter = false,
    is404,
    customLocation,
    triggerCashbackDrawer,
    addLoadingScreen,
    dontAddLoadingBetweenPages,
    dontShowPopups,
    page,
    eventLocation = '',
    navContext = null,
    hideKycModal = false,
  } = props
  const { isLoggedIn, countryBlocked } = useContext(AuthContext)
  const { popup, checkPopup } = usePopups()
  const [openSowModal, setOpenSowModal] = useState(false)
  const [showCashbackDrawer, setShowCashbackDrawer] = useState(false)
  const isFooterV2Enabled = useFeatures('footerV2')
  const { notices } = useMaintenanceApiData()
  const removeHeaderForMobileApp = getMobileAppVersion() >= 53
  useIntercomMessenger()
  const { showSEOContent } = useSEOContentVisibility()

  useEffect(() => {
    if (!isLoggedIn) {
      if (
        seoPathParams?.page === 'betHistory' ||
        seoPathParams?.page === 'settings'
      )
        navigate('/login')
    }
  }, [seoPathParams])

  useEffect(() => {
    const checkForCookies = () => {
      if (!isBrowser()) return
      const gaCookie = getCookie('_ga')
      const gaSessionCookie = getCookie(EnvConfig.GATSBY_GA_COOKIE)

      if (gaCookie) {
        sessionStorage.setItem('_ga', gaCookie)
      }
      if (gaSessionCookie) {
        sessionStorage.setItem(EnvConfig.GATSBY_GA_COOKIE, gaSessionCookie)
      }

      return gaCookie || gaSessionCookie
    }

    if (checkForCookies()) return

    const observer = new MutationObserver(() => {
      if (checkForCookies()) {
        observer.disconnect()
      }
    })

    observer.observe(document, {
      attributes: true,
      attributeFilter: ['cookie'],
    })

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    if (triggerCashbackDrawer > 0) toggleCashbackDrawer()
  }, [triggerCashbackDrawer])

  const handleOpenSowModal = () => {
    setOpenSowModal(true)
  }

  const toggleCashbackDrawer = () => {
    setShowCashbackDrawer((current) => !current)
  }

  const initGrowthbook = () => {
    growthbook.loadFeatures({ autoRefresh: true, timeout: 2000 })
  }

  useEffect(() => {
    initGrowthbook()
  }, [])

  if (countryBlocked) {
    navigate('/blocked')
    return <></>
  }

  if (isBrowser() && notices.length > 0 && notices[0]?.type === 'maintenance') {
    navigate('/maintenance')
    return <></>
  }

  const SHOW_KYC_MODAL = !isMobileApp() && !hideKycModal

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <GrowthBookProvider growthbook={growthbook}>
        <ThemeProvider theme={defaultTheme}>
          <PaymentsProvider>
            <ModalProvider>
              <GlobalStyle isLoggedIn={isLoggedIn} />
              <CoolOffBanner page={page} />
              <If
                condition={addLoadingScreen}
                render={() => (
                  <LoadingScreen
                    dontAddLoadingBetweenPages={dontAddLoadingBetweenPages}
                  />
                )}
              />
              <SuspensfulComponent componentName={'ToastContainer'} condition />
              <SuspensfulComponent componentName={'PlayerInbox'} />
              <If
                condition={!hideHeader && !removeHeaderForMobileApp}
                render={() => (
                  <Header
                    toggleCashbackDrawer={toggleCashbackDrawer}
                    page={page}
                  />
                )}
              />
              <If
                condition={isLoggedIn && popup && !dontShowPopups}
                render={() => <Popup popup={popup} checkPopup={checkPopup} />}
              />
              <CashbackDrawer
                isOpen={showCashbackDrawer}
                onClose={toggleCashbackDrawer}
              />
              <StyledMain>{children}</StyledMain>
              <If
                condition={!isLoggedIn && showSEOContent && !isFbUser()}
                render={() => (
                  <SeoContent
                    seoPathParams={seoPathParams}
                    customLocation={customLocation}
                  />
                )}
              />
              <SuspensfulComponent componentName={'CpfModal'} />
              <SuspensfulComponent
                componentName={'ShowTCModal'}
                childProps={{
                  openSowModal: handleOpenSowModal,
                }}
              />
              <SuspensfulComponent
                componentName={'SOWModal'}
                childProps={{ openSowModal, Widget }}
              />
              <SuspensfulComponent componentName={'Deposit'} />
              <BottomNavigation
                eventLocation={eventLocation}
                navData={navContext}
              />
              <If
                condition={!hideFooter}
                render={() =>
                  isFooterV2Enabled ? (
                    <Footer locale={locale} />
                  ) : (
                    <FooterV1 locale={locale} />
                  )
                }
              />
              <Maintenance locale={locale} />
              <CpfModal />
              <RealityCheckModal />

              <div id="modal-root" />
              <SuspensfulComponent componentName={'ModalContainer'} condition />
              <LiveAgentScript />
              <LicenseScript />
              <GeolocationTracker />
              <MobileRoutesListener is404={is404} />
              <CookiesBanner />
              <If
                condition={SHOW_KYC_MODAL}
                render={() => <KYCVerificationModal />}
              />
            </ModalProvider>
          </PaymentsProvider>
        </ThemeProvider>
      </GrowthBookProvider>
    </ErrorBoundary>
  )
}

Layout.defaultProps = {
  children: <></>,
  hideHeader: false,
  hideFooter: false,
  addLoadingScreen: false,
  dontAddLoadingBetweenPages: false,
  dontShowPopups: false,
  is404: false,
  triggerCashbackDrawer: 0,
  seoPathParams: null,
  customLocation: null,
  eventLocation: '',
  navContext: null,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  addLoadingScreen: PropTypes.bool,
  dontAddLoadingBetweenPages: PropTypes.bool,
  dontShowPopups: PropTypes.bool,
  is404: PropTypes.bool,
  triggerCashbackDrawer: PropTypes.number,
  seoPathParams: PropTypes.object,
  customLocation: PropTypes.object, // Mimicks window.location object
  eventLocation: PropTypes.string,
  navContext: PropTypes.object,
  hideKycModal: PropTypes.bool,
}

export { Layout }
