import { apiGET } from '../utils/adapterFunctions'

export const getKYCData = async () =>
  await apiGET('/players/kyc/documents/pending')

export const getKYCVerificationData = async () =>
  await apiGET('/users/documents', null)

export const getKYCOnboardingData = async (documentType) => {
  const params = { documentType }
  return await apiGET('/onboardings', { params })
}

export const getKYCStatusData = async (playerId) => {
  return await apiGET(`/players/${playerId}/kyc-status`, null)
}
